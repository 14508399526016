export const setepperFormMixin = {
  props: ['value'],
  data () {
    return {
      editableItem: this.value
    }
  },
  methods: {
    handleInput(e){
      this.editableItem[e.target.name] = e.target.value
      this.$emit('input', this.editableItem)
    },
    async isCompleted () {
      const valid = await this.$refs.observer.validate()
      return valid
    },
  }
}