<template>
  <div>
    <span
      v-if="de != null && de.status"
      :class="{
        'bg-danger': de.status == 'NOUVEAU',
        'bg-info': de.status == 'EN_COURS',
        'bg-primary': de.status == 'TERMINE',
        'bg-success': de.status == 'VALIDE',
      }"
      class="badge"
    >
      {{ de.categorieDemandeur }} | {{ de.status.replace("_", " ") }} |
      {{ de.niveauPriseEnCharge }}
      <!-- {{ de.estPriseEnCharge ? "PEC" : "NPEC" }} -->
    </span>
  </div>
</template>

<script>
export default {
  props: {
    de: {
      type: Object,
      require: true,
    },
  },
};
</script>
